import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const MenuItem = ({ english, spanish, price, extra1, extra2 }) => {
  return (
    <div className="menu-item">
      <div>{spanish}</div>
      {english && <div>({english})</div>}
      <div>{price}</div>
      {extra1 && <div>{extra1}</div>}
      {extra2 && <div>{extra2}</div>}
    </div>
  )
}

const DrinkMenuItem = ({ spanish, price }) => (
  <div className="drink-menu-item">
    <div>{spanish}</div>
    <div>{price}</div>
  </div>
)

const TakeawayMenu = () => {
  const data = useStaticQuery(graphql`
    query takeawayMenuQuery {
      allTakeawayMenuCsv {
        edges {
          node {
            english
            spanish
            price
            extra1
            extra2
            section
          }
        }
      }
    }
  `)

  const getMenuItems = section =>
    data.allTakeawayMenuCsv.edges
      .filter(m => m.node.section === section)
      .map(m => m.node)

  const tapas = getMenuItems("tapas")
  const raciones = getMenuItems("raciones")
  const desserts = getMenuItems("desserts")
  const drinks = getMenuItems("drinks")
  const extras = getMenuItems("extras")

  return (
    <div className="takeaway-menu-grid-container">
      <div className="green">
        <h3>TAPAS Y ENTRANTES</h3>
        {tapas.map((menuItem, index) => (
          <MenuItem key={index} {...menuItem} />
        ))}
      </div>
      <div className="blue">
        <h3>RACIONES & PARA COMPARTIR</h3>
        {raciones.map((menuItem, index) => (
          <MenuItem key={index} {...menuItem} />
        ))}
      </div>
      <div className="red">
        <h3>POSTRES / DESSERTS</h3>
        {desserts.map((menuItem, index) => (
          <MenuItem key={index} {...menuItem} />
        ))}
        <br />
        <h3>COCTELES / CAFES</h3>
        {drinks.map((menuItem, index) => (
          <DrinkMenuItem key={index} {...menuItem} />
        ))}
      </div>
      <div className="grey">
        <h3>EXTRAS</h3>
        {extras.map((menuItem, index) => (
          <DrinkMenuItem key={index} {...menuItem} />
        ))}
      </div>
    </div>
  )
}

export default TakeawayMenu
