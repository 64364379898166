import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const WineListItem = ({ name, glass_price, bottle_price }) => (
  <div className="wine-list-row">
    <div className="wine-list-label">{name}</div>
    <div className="wine-list-price">{glass_price}</div>
    <div className="wine-list-price">{bottle_price}</div>
  </div>
)

const WineList = () => {
  const data = useStaticQuery(graphql`
    query wineListQuery {
      allWineListCsv {
        edges {
          node {
            glass_price
            bottle_price
            name
            type
          }
        }
      }
    }
  `)

  const getMenuItems = type =>
    data.allWineListCsv.edges.filter(m => m.node.type === type).map(m => m.node)

  const whiteWines = getMenuItems("white")
  const redWines = getMenuItems("red")
  const rosieWines = getMenuItems("rosie")
  const cavaWines = getMenuItems("cava")
  return (
    <div className="wine-list-grid-container">
      <div className="white-wine">
        <h3>BLANCOS</h3>
        {whiteWines.map((menuItem, index) => (
          <WineListItem key={index} {...menuItem} />
        ))}
      </div>
      <div className="red-wine">
        <h3>TINTOS</h3>
        {redWines.map((menuItem, index) => (
          <WineListItem key={index} {...menuItem} />
        ))}
      </div>
      <div className="rosie-wine">
        <h3>ROSADOS</h3>
        {rosieWines.map((menuItem, index) => (
          <WineListItem key={index} {...menuItem} />
        ))}
      </div>
      <div className="cavas-wine">
        <h3>CAVAS</h3>
        {cavaWines.map((menuItem, index) => (
          <WineListItem key={index} {...menuItem} />
        ))}
      </div>
    </div>
  )
}

export default WineList
