import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Menu from "../components/menu"
import TakeawayMenu from "../components/takeawayMenu"
import WineList from "../components/wineList"

const menus = {
  menu: {
    title: "Menu",
    component: Menu,
  },
  wine: {
    title: "Wine list",
    component: WineList,
  },
  takeaway: {
    title: "Takeaway",
    component: TakeawayMenu,
  },
}

const MenuPage = () => {
  const [selectedMenu, setSelectedMenu] = useState("menu")

  const { component: SelectedMenu } = menus[selectedMenu]

  return (
    <Layout>
      <SEO title="Menu" />
      <section className="container section">
        <div className="menu-header-container">
          {Object.keys(menus).map(key => {
            const isSelected = key === selectedMenu
            return (
              <button
                key={key}
                css={{
                  textDecoration: isSelected ? "underline" : "none",
                  padding: "5px 10px",
                  color: isSelected
                    ? "rgb(13, 33, 70)"
                    : "rgba(13, 33, 70, 0.5)",
                  backgroundColor: isSelected ? "#fff" : "#F8F8F8",
                  "@media screen and (min-width:630px)": {
                    padding: "10px 20px",
                  },
                }}
                onClick={() => setSelectedMenu(key)}
              >
                {menus[key].title}
              </button>
            )
          })}
        </div>
        <SelectedMenu />
        <br />
        <div>
          POR FAVOR ANTES DE PEDIR, INFORMAR DE TUS ALERGIAS (BEFORE ORDERING
          PLEASE ADVISE US OF ANY FOOD ALLERGIES OR INTOLERANCES)
        </div>
      </section>
    </Layout>
  )
}

export default MenuPage
