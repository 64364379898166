import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const MenuItem = ({ english, spanish, price }) => (
  <div className="menu-item">
    <div>{spanish}</div>
    {english && <div>({english})</div>}
    <div>{price}</div>
  </div>
)

const DrinkMenuItem = ({ spanish, price }) => (
  <div className="drink-menu-item">
    <div>{spanish}</div>
    <div>{price}</div>
  </div>
)

const Menu = () => {
  const data = useStaticQuery(graphql`
    query menuQuery {
      allMainMenuCsv {
        edges {
          node {
            english
            spanish
            section
            price
          }
        }
      }
    }
  `)

  const getMenuItems = section =>
    data.allMainMenuCsv.edges
      .filter(m => m.node.section === section)
      .map(m => m.node)

  const tapas = getMenuItems("tapas")
  const raciones = getMenuItems("raciones")
  const desserts = getMenuItems("desserts")
  const drinks = getMenuItems("drinks")

  return (
    <div className="menu-grid-container">
      <div className="green">
        <h3>TAPAS Y ENTRANTES</h3>
        {tapas.map((menuItem, index) => (
          <MenuItem key={index} {...menuItem} />
        ))}
      </div>
      <div className="blue">
        <h3>RACIONES</h3>
        {raciones.map((menuItem, index) => (
          <MenuItem key={index} {...menuItem} />
        ))}
      </div>
      <div className="red">
        <h3>POSTRES / DESSERTS</h3>
        {desserts.map((menuItem, index) => (
          <MenuItem key={index} {...menuItem} />
        ))}
        <br />
        <div>Selección de cafés, tés y licores premium</div>
      </div>
      <div className="orange">
        <h3>COMBINADO Y COCTELES</h3>
        {drinks.map((menuItem, index) => (
          <DrinkMenuItem key={index} {...menuItem} />
        ))}
      </div>
    </div>
  )
}

export default Menu
